const Partners = {
  init: () => {
    if ($("#partners-index-container").length) {
      // Utils.setupMasonry();
      Partners.setupCountrySelect();
    }

    if ($("#partners-show-container").length) {
      $(".photo-container .slick-container").slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 380,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });

      Utils.setupMagnificPopup('.magnific-popup-container', '.magnific-popup-item-container');
    }
  },
  setupCountrySelect: () => {
    let country_ids, port_ids
    let country_select = $(".country-select");

    country_select.on("change", function() {
      $(".ports-list").val("")

      $(this).closest("form").submit()
    })

    country_ids = country_select.val()
    port_ids = $(".ports-list").val().split(", ")

    if (country_ids) {
      Partners.get_ports_by_country(country_ids, port_ids)
    }
  },
  get_ports_by_country: (country_ids, port_ids) => {
    $.ajax({
      type: 'POST',
      url: '/countries/get_ports',
      data: {
        country_ids: country_ids,
        port_ids: port_ids
      },
      dataType: "script"
    })
  }
};

export default Partners;
