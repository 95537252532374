const ClientRecommendPartners = {
  init: () => {
    if($(".partners-recommend-result-container").length) {
      $(".result-container .slick-container").slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        prevArrow: '<span class="slider-pre-btn"></span>',
        nextArrow: '<span class="slider-next-btn"></span>',
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 380,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });
    }
  }
};

export default ClientRecommendPartners;
