const PartnerChat = {
  init: () => {
    Cookies.set("chat_type", 'partner');

    if (Cookies.get('chat_container') === 'open') {
      PartnerChat.set_show_chat();
      PartnerChat.get_chat_rooms();
    }

    $(document).on("click", ".chat-icon", function() {
      if (Cookies.get('chat_container') === 'open') {
        PartnerChat.set_hide_chat();
      } else {
        PartnerChat.set_show_chat();
        PartnerChat.get_chat_rooms();
      }
    })

    $(document).on("click", ".chat-header-right .minimize", function() {
      PartnerChat.set_hide_chat();
    })
    
    $(document).on("keydown", "#chat-text", function(e) {
      if (e.keyCode == 13 && !e.shiftKey) {
        $('#form_message form').submit();
      }
    });

    $(document).on("click", "#plus-btn", function(e){
      $("#actions-dropdown").toggleClass("visible");
    });
    $(document).on("change", ".chat-upload-file-input", function(e){
      $(this).closest("form").find(".chat-upload-file-submit-btn").click()
    })

    setInterval(function () {
      // PartnerChat.get_clients();
    }, 60000);
  },
  get_chat_rooms: () => {
    $.ajax({
      type: 'GET',
      url: '/partner/chat_rooms.js'
    })
  },
  set_hide_chat: () => {
    Cookies.set("chat_container", 'close');
    $('.chat-container').removeClass('show');
    $('.chat-container').addClass('hide');
  },
  set_show_chat: () => {
    Cookies.set("chat_container", 'open');
    $('.chat-container').removeClass('hide');
    $('.chat-container').addClass('show');
  },
  get_clients: () => {
    $.ajax({
      type: 'GET',
      url: '/partner/chat_rooms/get_clients.js'
    })
  }
};

export default PartnerChat;
