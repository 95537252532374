const ClientUsers = {
  init: () => {
    if ($("#client-users-index-container").length) {
      ClientUsers.setupFilterForm()
    }
  },
  setupFilterForm: () => {
    $('#filter-form-container').on('shown.bs.collapse', function () {
      Cookies.set("client_user_filter_collapse", true);
    })

    $('#filter-form-container').on('hidden.bs.collapse', function () {
      Cookies.remove("client_user_filter_collapse");
    })

    $(document).on("keypress", "#filter-form-container input", function(e) {
      if (e.which == 13) {
        $(this).closest("form").submit()
      }
    })

    $(document).on("click", ".btn-reset-collapse-form", function() {
      let current_form = $(this).closest("form")
    
      current_form.find("select").val("")
      current_form.find("input").val("")

      $(this).closest("form").submit()
    })

    $(document).on("click", ".search-with-icon-container a", function() {
      $(this).closest("form").submit()
    })
  }
};

export default ClientUsers;
