import "../../../vendor/Date-Time-Picker-Bootstrap-4/build/js/bootstrap-datetimepicker.min";

const Bags = {
  init: () => {
    if ($("#bags-index-container").length) {
      Bags.setupBagFilter();
    }

    if ($("#bags-bagging-container").length) {
      $(document).on("click", ".order-wrap", function() {
        const id = $(this).attr('data-id');
        const group = $(this).attr('data-group');
        const selected = $(this).attr('data-select');
        const targetInput = $(document).find(`input.form-orders-group-${group}`);
        const selectedOrders = $(document).find(`.selected-orders-group-${group} span.content`)
        let orderIds;
        const inputValue = targetInput.val();

        if (inputValue) {
          orderIds = inputValue.split(',');
        } else {
          orderIds = [];
        }

        if (selected == 'none') {
          $(this).attr('data-select', 'selected');
          $(this).addClass('active');
          orderIds.push(id);
        } else {
          $(this).attr('data-select', 'none');
          $(this).removeClass('active');
          orderIds = orderIds.filter(item => item !== id.toString())
        }

        selectedOrders.text(orderIds.length);

        targetInput.val(orderIds.join(','));
      })

      let barcodeScanned = "";
      let timer = null;
      $(document).on("keypress", function(e) {
        if (e.which === 13) {
          $(document).find(`.order-wrap[data-order-code="${barcodeScanned}"]:not(.active)`).click();
          barcodeScanned = "";
        } else {
          barcodeScanned += e.key;
          if (timer) {
            clearTimeout(timer);
          }
          timer = setTimeout(() => {
            console.log('scanned:', barcodeScanned);
            $(document).find(`.order-wrap[data-order-code="${barcodeScanned}"]:not(.active)`).click();
            barcodeScanned = "";
          }, 100);
        }
      })
    }

    if ($("#bags-index-container").length) {
      $(document).on("click", ".order-wrap", function() {
        const id = $(this).attr('data-id');
        const group = $(this).attr('data-group');
        const selected = $(this).attr('data-select');
        const targetInput = $(document).find(`input.form-orders-group-${group}`);
        const selectedOrders = $(document).find(`.selected-orders-group-${group} span.content`)
        let orderIds;
        const inputValue = targetInput.val();

        if (inputValue) {
          orderIds = inputValue.split(',');
        } else {
          orderIds = [];
        }

        if (selected == 'none') {
          $(this).attr('data-select', 'selected');
          $(this).addClass('active');
          orderIds.push(id);
        } else {
          $(this).attr('data-select', 'none');
          $(this).removeClass('active');
          orderIds = orderIds.filter(item => item !== id.toString())
        }

        selectedOrders.text(orderIds.length);

        targetInput.val(orderIds.join(','));
      });

      let barcodeScanned = "";
      let timer = null;
      $(document).on("click", ".btn-start-scan-barcode", function() {
        barcodeScanned = "";
      })
      $(document).on("keypress", function(e) {
        if (e.which === 13) {
          $(document).find(`.order-wrap[data-order-code="${barcodeScanned}"]:not(.active)`).click();
          barcodeScanned = "";
        } else {
          barcodeScanned += e.key;
          if (timer) {
            clearTimeout(timer);
          }
          timer = setTimeout(() => {
            console.log('scanned:', barcodeScanned);
            $(document).find(`.order-wrap[data-order-code="${barcodeScanned}"]:not(.active)`).click();
            barcodeScanned = "";
          }, 100);
        }
      })
    }
  },
  setupBagFilter: () => {
    Bags.setupDateRangePicker();
    Bags.setupDateAutoSubmit();
  },
  // setupDateAutoSubmit: () => {
  //   let flag = false;

  //   $(document).on("dp.change", ".date_filter", function(e){
  //     if(flag) {
  //       $(this).closest("form").submit()
  //     }

  //     flag = true;
  //   })
  // },
  setupDateRangePicker: () => {
    var format_date = "ddd, DD.MM.yyyy";
    var format_input_date = "YYYY/MM/DD";
    var range = $('.date-range-filter-container #bag-date-range-input').val().split('-');
    var start = Moment(range[0], format_input_date);
    var end = Moment(range[1], format_input_date);

    function cb(start, end) {
      $('.date-range-filter-container #reportrange span').html(start.format(format_date) + ' - ' + end.format(format_date));
      $('.date-range-filter-container #bag-date-range-input').val(start.format(format_input_date) + '-' + end.format(format_input_date));
    }

    $('.date-range-filter-container #reportrange').daterangepicker({
      startDate: start,
      endDate: end,
      ranges: {
        'Today': [Moment(), Moment()],
        'Yesterday': [Moment().subtract(1, 'days'), Moment().subtract(1, 'days')],
        'Last 7 Days': [Moment().subtract(6, 'days'), Moment()],
        'Last 30 Days': [Moment().subtract(29, 'days'), Moment()],
        'This Month': [Moment().startOf('month'), Moment().endOf('month')],
        'Last Month': [Moment().subtract(1, 'month').startOf('month'), Moment().subtract(1, 'month').endOf('month')],
        'This Year':  [Moment().startOf('year'), Moment().endOf('year')],
      }
    }, cb);

    cb(start, end);
  },
  setupDateAutoSubmit: () => {
    // $(document).on("dp.change", ".date_filter", function(e){
    //   const cookieName = $(this).attr('data-filtername');
    //   Cookies.set(cookieName, $(this).val());
    //   $(this).closest("form").submit()
    // })

    $('#reportrange').on('apply.daterangepicker', function(ev, picker) {
      // console.log('--- AAA ---');
      // $("#dashboard-date-range-filter-form").submit();
      const cookieName = $('#bag-date-range-input').attr('data-filtername');
      // console.log('cookieName: ', cookieName);
      // console.log('cookieName: ', $('#bag-date-range-input').val());
      Cookies.set(cookieName, $('#bag-date-range-input').val());
      $(this).closest("form").submit();
    });
  }
};

export default Bags;
