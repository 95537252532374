// import "./utils";
// import "magnific-popup";

const ClientChat = {
  init: () => {
    Cookies.set("chat_type", 'client');

    if (Cookies.get('chat_container') === 'open') {
      ClientChat.set_show_chat();
      ClientChat.get_chat_rooms();
    }

    $(document).on("click", ".chat-icon", function() {
      if (Cookies.get('chat_container') === 'open') {
        ClientChat.set_hide_chat();
      } else {
        ClientChat.set_show_chat();
        ClientChat.get_chat_rooms();
      }
    })

    $(document).on("click", ".chat-header-right .minimize", function() {
      ClientChat.set_hide_chat();
    })

    $(document).on("keydown", "#chat-text", function(e) {
      if (e.keyCode == 13 && !e.shiftKey) {
        $('#form_message form').submit();
      }
    });

    $(document).on("click", "#plus-btn", function(e){
      $("#actions-dropdown").toggleClass("visible");
    });

    $(document).on("change", ".chat-upload-file-input", function(e){
      $(this).closest("form").find(".chat-upload-file-submit-btn").click()
    })

    setInterval(function () {
      // ClientChat.get_partners();
    }, 60000);
    // Utils.setupMagnificPopup('.magnific-popup-container', '.magnific-popup-item-container');
  },
  get_chat_rooms: () => {
    $.ajax({
      type: 'GET',
      url: '/chat_rooms.js'
    })
  },
  set_hide_chat: () => {
    Cookies.set("chat_container", 'close');
    $('.chat-container').removeClass('show');
    $('.chat-container').addClass('hide');
  },
  set_show_chat: () => {
    Cookies.set("chat_container", 'open');
    $('.chat-container').removeClass('hide');
    $('.chat-container').addClass('show');
  },
  get_partners: () => {
    $.ajax({
      type: 'GET',
      url: '/chat_rooms/get_partners.js'
    })
  }
};

export default ClientChat;
