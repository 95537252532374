const MyPartners = {
  init: () => {
    if ($("#my-partners-index-container").length) {
      MyPartners.setupCountrySelect();
    }
  },
  setupCountrySelect: () => {
    let country_ids, port_ids
    let country_select = $(".country-select");

    country_select.on("change", function() {
      $(".ports-list").val("")

      $(this).closest("form").submit()
    })

    country_ids = country_select.val()
    port_ids = $(".ports-list").val().split(", ")

    if (country_ids) {
      MyPartners.get_ports_by_country(country_ids, port_ids)
    }
  },
  get_ports_by_country: (country_ids, port_ids) => {
    $.ajax({
      type: 'POST',
      url: '/countries/get_ports',
      data: {
        country_ids: country_ids,
        port_ids: port_ids
      },
      dataType: "script"
    })
  }
};

export default MyPartners;
