import consumer from "./consumer"
import PartnerChat from "../packs/partner/chat"
import ClientChat from "../packs/chat"

consumer.subscriptions.create("ChatMessagesChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    if ($("#chat_messages").contents().length !== 0) {
      $("#chat_messages").scrollTop($("#chat_messages")[0].scrollHeight);
    }
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    if ($(`#chat_messages[chat-room-id=${data.chat_message.chat_room_id}]`).length) {
      $(`#chat_messages`).append(data.html);
      $("#chat_messages").scrollTop($("#chat_messages")[0].scrollHeight);
      $("#chat-text").val('');

      if (Cookies.get('chat_type') === 'client') {
        ClientChat.get_partners();
      }

      if (Cookies.get('chat_type') === 'partner') {
        PartnerChat.get_clients();
      }
    }
  }
});