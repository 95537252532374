const Luwjistik = {
  init: () => {
    Cookies.set("local_time_zone", Intl.DateTimeFormat().resolvedOptions().timeZone);

    Luwjistik.reloadOrderStatusReason();
    Luwjistik.reloadCountryCodeSelect();

    $(document).on('cocoon:after-insert', 'form', function(e) {
      Utils.setupSelectize()
    })

    // Luwjistik.show_partner("first_mile")
    // Luwjistik.show_partner("freight_forwarding")
    // Luwjistik.show_partner("custom_brokerages")
    // Luwjistik.show_partner("last_mile")
  },
  reloadOrderStatusReason: () => {
    $(document).on("change", ".order-status-select", function() {
      Luwjistik.getReasonByStatus($(this).val())
    })
  },
  getReasonByStatus: status => {
    $.ajax({
      type: 'GET',
      url: '/order-statuses/get_reasons',
      data: {
        status: status
      },
      dataType: "script"
    })
  },
  reloadCountryCodeSelect: () => {
    let country_select, country_code_input, country_code

    country_select = $(".country-code-select")
    country_code_input = $(".show-country-code")

    country_select.on("change", function(e) {
      country_code = e.target.value

      country_code_input.val(country_code)
    })

    country_code_input.on("keyup", function(e) {
      country_code = e.target.value

      Luwjistik.reloadCountryCodeDropdown(country_select, country_code)
    })

    country_code = country_select.val()

    if (country_code) {
      country_code_input.html(`+${country_code}`)

      Luwjistik.reloadCountryCodeDropdown(country_select, country_code)
    }
  },
  reloadCountryCodeDropdown: (country_select, country_code) => {
    Utils.resetSelectizeOfElement(country_select)
    country_select.val(country_code)
    Utils.setupSelectizeForElement(country_select)
  },
  show_partner: target => {
    $("." + target).each(function(e) {
      let next_el = $(this).next()

      if (!next_el || (next_el && !next_el.hasClass(target))) {
        $(this).find('.partner').css("display", "block")
      }
    })
  }
};

export default Luwjistik;
