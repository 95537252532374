import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "bootstrap"
import "selectize"
import "slick-carousel"
import "jquery.easing"
import "magnific-popup";
import "dropzone";
import "./bootstrap-notify.min";
import "./daterangepicker.min";
import Masonry from "masonry-layout";
import { Modal } from "bootstrap";
import "@nathanvda/cocoon";
import Cookies from "./cookie";
import intlTelInput from 'intl-tel-input';
import Promise from 'bluebird';
import JsZip from 'jszip';
import FileSaver from 'file-saver';
import Cleave from 'cleave.js';
import Moment from 'moment';

import Utils from "./utils";
import Luwjistik from "./global";
import Partners from "./partners";
import MyPartners from "./my_partners";
import Orders from "./orders";
import ClientUsers from "./client/users";
import ClientRecommendPartners from "./client/recommend_partners";
import Chat from "./chat";
import Bags from "./bags";

import "./chart";
import Dashboard from "./dashboard";


global.$ = jQuery;
global.Utils = Utils;
global.Masonry = Masonry;
global.Partners = Partners;
global.Modal = Modal;
global.Cookies = Cookies;
global.intlTelInput = intlTelInput;
global.JsZip = JsZip;
global.FileSaver = FileSaver;
global.Promise = Promise;
global.Cleave = Cleave;
global.Moment = Moment;
global.Dashboard = Dashboard;

Dropzone.autoDiscover = false;

Rails.start()
ActiveStorage.start()

$(document).ready(function () {
  Utils.init();
  Luwjistik.init();
  Partners.init();
  Orders.init();
  ClientUsers.init();
  MyPartners.init();
  Chat.init();
  Bags.init();
  ClientRecommendPartners.init();
  Dashboard.init();
});
