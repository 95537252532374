import JsZip from 'jszip';
import FileSaver from 'file-saver';

const Utils = {
  init: () => {
    Utils.setupSelectize()
    Utils.setupTogglePassword()
    Utils.setupPhoneNumberInput()
    Utils.setupInputNumber()
    Utils.setupTooltip()
  },
  openNotification: (message = '', type = 'success', effect_enter = 'slideInRight', effect_exit = 'slideOutRight') => {
    if ($('body #popup').length) {
      popup.hide()
    }

    $.notify(message, {
      type: type,
      animate: {
        enter: `animated ${effect_enter}`,
        exit: `animated ${effect_exit}`
      },
      placement: {
        from: "bottom"
      },
      timer: 200,
      delay: 7000
    })
  },
  set_will_paginate_per_page: e => {
    let per_page = $(e).data('per-page');
    let currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('per_page', per_page);

    window.location = currentUrl.href;
  },
  toogleMobileMenu: e => {
    $(e).toggleClass('open')
    $("header").toggleClass('mobile')
    $(".menu-main-container, .client-menu-main-container, .shared-header-container .right-container, .client-shared-header-container .right-container").toggleClass('open')
  },
  setupMagnificPopup: (wrap, delegate = 'a', type = 'image') => {
    $(wrap).magnificPopup({
      delegate: delegate,
      type: type,
      closeOnContentClick: false,
      closeBtnInside: false,
      mainClass: 'mfp-with-zoom mfp-img-mobile',
      gallery: {
        enabled: true
      },
      zoom: {
        enabled: true,
        duration: 300,
        easing: 'ease-in-out',
        opener: function(element) {
          return element.find('img');
        }
      }
    });
  },
  resetSelectize: () => {
    $("select.selectized").each(function(e) {
      $(this)[0].selectize.destroy()
    })
  },
  setupSelectize: () => {
    $('.selectize.selectized').each(function() {
      Utils.resetSelectizeOfElement(this, 'selectize')
    })

    $("select").each(function() {
      let multiple = $(this).prop('multiple');
      let plugins = [];
      
      if (multiple) { plugins.push('remove_button'); }

      $(this).selectize({
        plugins: plugins,
        delimiter: ',',
        preload: true
      });
    })

    $('.selectize-allow-create.selectized').each(function() {
      Utils.resetSelectizeOfElement(this, 'selectize-allow-create')
    })

    $(".selectize-allow-create").each(function() {
      $(this).selectize({
        delimiter: ',',
        create: true,
        sortField: 'text',
        plugins: ['remove_button']
      });
    });
  },
  resetSelectizeOfElement: e => {
    $(e)[0].selectize.destroy()
  },
  setupSelectizeForElement: e => {
    let multiple = $(e).prop('multiple');
    let plugins = [];
    
    if (multiple) { plugins.push('remove_button'); }

    $(e).selectize({
      plugins: plugins,
      delimiter: ',',
      preload: true
    });
  },
  readURL: input => {
    let file = input.files[0];
    let reader = new FileReader();

    reader.onload = (function(data) {
      return function(e) {
        let label = $(input).closest("label")
        $(label).find("img").attr("src", e.target.result);
      };
    })(file);
    reader.readAsDataURL(file);
  },
  setupMasonry: () => {
    new Masonry( '.masonry-container', { itemSelector: '.masonry-item', percentPosition: true });
  },
  setupTogglePassword: () => {
    $('.form-group.password').each(function () {
      if ($(this).find('.btn-toggle-password').length == 0) {
        $(this).append('<a href="" class="btn-toggle-password"><i class="eye-slash" aria-hidden="true"></i></a>')
      }
    })

    $(".password a").on('click', function (event) {
      event.preventDefault();

      var parent = $(this).closest('.password');
      var input = parent.find('input');
      var icon = $(this).find('i');

      if (input.attr("type") == "text") {
        input.attr('type', 'password');
        icon.addClass("eye-slash");
        icon.removeClass("eye");
      } else if (input.attr("type") == "password") {
        input.attr('type', 'text');
        icon.removeClass("eye-slash");
        icon.addClass("eye");
      }
    });
  },
  allow_edit_field: e => {
    let wrapper = $(e).closest(".nested-fields")
    let field = wrapper.find("input.form-control")
    let value = field.val()

    wrapper.removeClass('disabled');
    field.prop('disabled', false);
    field.focus().val('').val(value);
  },
  copyToClipboard: e => {
    let copyText = $(e).closest('.input').find('span')
    let temp = $("<input>");

    $("body").append(temp);

    temp.val($(copyText).html()).select();

    document.execCommand("copy");

    temp.remove();

    $(e).text("Copied");
  },
  removeElementFromArray: (array, el) => {
    let index = array.indexOf(el);

    if (index > -1) {
      array.splice(index, 1);
    }

    return array;
  },
  setupPhoneNumberInput: () => {
    $(".phone-input").each(function() {
      const input = this;
      const iti = intlTelInput(input, {
        nationalMode: true,
        initialCountry: 'sg'
      });

      input.addEventListener("countrychange", function() {
        let country = iti.getSelectedCountryData()
        let value = $(this).val()

        if (!value.includes("+")) {
          $(this).val(`+${country.dialCode}${value}`)
        }
      });

      $(input).on("keyup", function() {
        let value = $(this).val()

        if (value == "") {
          $(this).val("+")
        } else {
          if (!value.includes("+")) {
            $(this).val(`+${value}`)
          }
        }
      })
    })
  },
  downloadBlob: url => {
    return fetch(url).then(resp => resp.blob());
  },
  downloadAndZipSingleOrder: async (labels, orderCode) => {
    const blobs = [];

    for (var label of labels) {
      var blob = await Utils.downloadBlob(label.url);
      blobs.push({blob: blob, key: label.key});
    }

    return Utils.exportZipSingleOrder(blobs, orderCode);
  },
  exportZipSingleOrder: (blobs, orderCode) => {
    var zip = JsZip();

    for (var blob of blobs) {
      // var parentFolder = zip.folder(`labels`);
      var folder = zip.folder(orderCode);
      folder.file(`${blob.key.toUpperCase()}.pdf`, blob.blob);
    }

    zip.generateAsync({type: 'blob'}).then(zipFile => {
      var fileName = `label.zip`;
      return FileSaver.saveAs(zipFile, fileName);
    });
  },
  downloadAndSave: async (fileName, url) => {
    var blob = await Utils.downloadBlob(url);
    return FileSaver.saveAs(blob, fileName);
  },
  downloadAndZipMultiOrder: async labels => {
    const blobs = [];

    for (var order of labels) {
      for (var [orderCode, labelFiles] of Object.entries(order)) {
        for (var [key, url] of Object.entries(labelFiles)) {
          var blob = await Utils.downloadBlob(url);
          Utils.openNotification(orderCode + '.pdf is downloaded successfully!')

          blobs.push({blob: blob, key: key, orderCode: orderCode});
        }
      }
    }

    return Utils.exportZipMultiOrder(blobs);
  },
  exportZipMultiOrder: blobs => {
    var zip = JsZip();

    for (var blob of blobs) {
      var parentFolder = zip.folder(`labels`);
      var folder = parentFolder.folder(blob.orderCode);
      folder.file(`${blob.key.toUpperCase()}.pdf`, blob.blob);
    }

    zip.generateAsync({type: 'blob'}).then(zipFile => {
      var fileName = `labels.zip`;
      return FileSaver.saveAs(zipFile, fileName);
    });
  },
  setupInputNumber: () => {
    $('.input-number').each(function() {
      let cleave = new Cleave($(this), {
        numeral: true,
        numeralDecimalScale: 2
      })
    })
  },
  setupTooltip: () => {
    $('[data-toggle="tooltip"]').tooltip({html:true});
  }
};

export default Utils;
