const Dashboard = {
  init: () => {
    if ($("#home-index-container").length) {
      Dashboard.setupDateRangePicker();
    }
    Dashboard.dateRangeChanged();
    Dashboard.selectCurrencyChanged();
  },
  setupDateRangePicker: () => {
    var format_date = "ddd, DD.MM.yyyy";
    var format_input_date = "YYYY/MM/DD";
    var range = $('.date-range-filter-container #dashboard-date-range-input').val().split('-');
    var start = Moment(range[0], format_input_date);
    var end = Moment(range[1], format_input_date);

    function cb(start, end) {
      $('.date-range-filter-container #reportrange span').html(start.format(format_date) + ' - ' + end.format(format_date));
      $('.date-range-filter-container #dashboard-date-range-input').val(start.format(format_input_date) + '-' + end.format(format_input_date));
    }

    $('.date-range-filter-container #reportrange').daterangepicker({
      startDate: start,
      endDate: end,
      ranges: {
        'Today': [Moment(), Moment()],
        'Yesterday': [Moment().subtract(1, 'days'), Moment().subtract(1, 'days')],
        'Last 7 Days': [Moment().subtract(6, 'days'), Moment()],
        'Last 30 Days': [Moment().subtract(29, 'days'), Moment()],
        'This Month': [Moment().startOf('month'), Moment().endOf('month')],
        'Last Month': [Moment().subtract(1, 'month').startOf('month'), Moment().subtract(1, 'month').endOf('month')],
        'This Year':  [Moment().startOf('year'), Moment().endOf('year')],
      }
    }, cb);

    cb(start, end);
  },
  dateRangeChanged: () => {
    $('#reportrange').on('apply.daterangepicker', function(ev, picker) {
      $("#dashboard-date-range-filter-form").submit();
    });
  },
  selectCurrencyChanged: () => {
    $("#dashboard_filter_currency").on("change", function(){
      $("#dashboard-date-range-filter-form").submit();
    })
  }
}
export default Dashboard;